import { useAuth } from "~/stores/auth.js";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (
    to.path === "/setup" ||
    to.path === "/login" ||
    to.path === "/settlement"
  ) {
    return;
  }

  if (useAuth().hasClosingError) {
    return navigateTo("/settlement");
  }
});
