import { default as indexxejXRj5smZMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/index.vue?macro=true";
import { default as logink58nqXfrm6Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/login.vue?macro=true";
import { default as _91id_93qkkqaC7siqMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/orders/[id].vue?macro=true";
import { default as createhLSJsRaGKtMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/orders/create.vue?macro=true";
import { default as indexFghVnEjUKdMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/orders/index.vue?macro=true";
import { default as print_45jobseuFX7iCBJrMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/print-jobs.vue?macro=true";
import { default as indexRlBniyxFhVMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/reservations/index.vue?macro=true";
import { default as lanesy7ewaFd3T6Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/reservations/lanes.vue?macro=true";
import { default as settlementETwNXs1vqKMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/settlement.vue?macro=true";
import { default as setupemZSkYOMHZMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/setup.vue?macro=true";
import { default as editJ52HNzbAsVMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/stock-statuses/[id]/edit.vue?macro=true";
import { default as indexAdAthcUoVMMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/stock-statuses/[id]/index.vue?macro=true";
import { default as createC3wEQLdp9DMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/stock-statuses/create.vue?macro=true";
import { default as indexHax1B9UNICMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/stock-statuses/index.vue?macro=true";
import { default as stock_45statusesJw7XdS2gERMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/stock-statuses.vue?macro=true";
import { default as index1uxCHDGjYPMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/tables/[tableId]/orders/[orderId]/index.vue?macro=true";
import { default as splitNmwXgSigdDMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/tables/[tableId]/orders/[orderId]/split.vue?macro=true";
import { default as _91orderId_93z2ADUel1qEMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/tables/[tableId]/orders/[orderId].vue?macro=true";
import { default as createiO5OJDwkukMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/tables/[tableId]/orders/create.vue?macro=true";
import { default as _91tableId_93abRrtnVhGgMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/tables/[tableId].vue?macro=true";
import { default as indexipFKnf0gBFMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/tables/index.vue?macro=true";
import { default as indexnKDqdcegEvMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/takeaway-orders/[id]/index.vue?macro=true";
import { default as splitannv8Az3k9Meta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/takeaway-orders/[id]/split.vue?macro=true";
import { default as _91id_9322cM18xASzMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/takeaway-orders/[id].vue?macro=true";
import { default as createsIxcWhXr9pMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/takeaway-orders/create.vue?macro=true";
import { default as indexLLRH5ysM8GMeta } from "/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/takeaway-orders/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logink58nqXfrm6Meta || {},
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders-create",
    path: "/orders/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/orders/create.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "print-jobs",
    path: "/print-jobs",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/print-jobs.vue").then(m => m.default || m)
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/reservations/index.vue").then(m => m.default || m)
  },
  {
    name: "reservations-lanes",
    path: "/reservations/lanes",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/reservations/lanes.vue").then(m => m.default || m)
  },
  {
    name: "settlement",
    path: "/settlement",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/settlement.vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    meta: setupemZSkYOMHZMeta || {},
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: stock_45statusesJw7XdS2gERMeta?.name,
    path: "/stock-statuses",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/stock-statuses.vue").then(m => m.default || m),
    children: [
  {
    name: "stock-statuses-id-edit",
    path: ":id()/edit",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/stock-statuses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-id",
    path: ":id()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/stock-statuses/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/stock-statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses",
    path: "",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/stock-statuses/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables-tableId",
    path: "/tables/:tableId()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/tables/[tableId].vue").then(m => m.default || m),
    children: [
  {
    name: _91orderId_93z2ADUel1qEMeta?.name,
    path: "orders/:orderId()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/tables/[tableId]/orders/[orderId].vue").then(m => m.default || m),
    children: [
  {
    name: "tables-tableId-orders-orderId",
    path: "",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/tables/[tableId]/orders/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId-orders-orderId-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/tables/[tableId]/orders/[orderId]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables-tableId-orders-create",
    path: "orders/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/tables/[tableId]/orders/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables",
    path: "/tables",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/tables/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_9322cM18xASzMeta?.name,
    path: "/takeaway-orders/:id()",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/takeaway-orders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "takeaway-orders-id",
    path: "",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/takeaway-orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders-id-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/takeaway-orders/[id]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "takeaway-orders-create",
    path: "/takeaway-orders/create",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/takeaway-orders/create.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders",
    path: "/takeaway-orders",
    component: () => import("/var/www/sites/terminal.pos2.halifax.dk/releases/20241115125426/pages/takeaway-orders/index.vue").then(m => m.default || m)
  }
]